import { ApiStore, Widget, rotationModes } from 'hart-estate-widget';
import '../assets/sass/index.sass';
import logo from '../assets/img/logo.png';

const WIDGET_API_URL = 'https://backend.estate.hart-digital.com';

const createWidget = async (logoUrl) => {
  const widgetApiHandler = new ApiStore(WIDGET_API_URL)

  const searchParams = (new URL(document.location)).searchParams;
  const planId = searchParams.get("id");

  const planData = await widgetApiHandler.loadWidgetData(planId);
  const options = {
    ...planData.parsed,
    API_URL: WIDGET_API_URL,
    rotationMode: rotationModes.DEFAULT,
    logo: logo,
    tabs: ['panorama', 'rotation'],
    locale: 'etalongroup',
    logoUrl,
  }

  new Widget('#widget', options);
}

createWidget('https://www.etalongroup.com/ru/');
